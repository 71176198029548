import * as React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'

// COMPONENTS
import { JobPosting } from '../Careers/JobPosting'

// HELPERS
import cls from '../../helpers/classNamesRendering'

export const FAQ = ({ data, styles }) => {
  const { jobs, jobRequirementsTitle } = data

  return (
    <Accordion className="accordion" allowZeroExpanded>
      {jobs.map((item) => (
        <AccordionItem
          key={item.uuid}
          className={cls('accordion__item', styles)}
        >
          <AccordionItemState>
            {({ expanded }) => (
              <>
                <AccordionItemHeading
                  className={
                    expanded
                      ? 'accordion__heading js__active heading--secondary'
                      : 'accordion__heading heading--secondary'
                  }
                >
                  <AccordionItemButton
                    className={
                      expanded
                        ? 'accordion__button js__active'
                        : 'accordion__button'
                    }
                  >
                    {item.jobTitle}
                    <span>+</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <JobPosting
                    job={item}
                    jobRequirementsTitle={jobRequirementsTitle}
                  />
                </AccordionItemPanel>
              </>
            )}
          </AccordionItemState>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
