import * as React from 'react'

export const FunnelSVG = ({ selectedTab }) => {
  return (
    <svg viewBox="0 0 214 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon_funnel">
        <g
          className={`${'tofu' === selectedTab ? 'tofu js__active' : 'tofu'} `}
        >
          <path
            id="fill_main"
            d="M1.74902 2.08594C48.4965 2.78621 99.5919 2.62554 148.817 2.46489C170.353 2.39486 191.532 2.32486 211.831 2.32692C206.697 18.7175 200.926 34.9309 194.003 50.4317C140.41 51.4657 77.8154 52.4831 23.4699 50.1516C15.2705 35.4087 7.72813 20.3446 1.74902 2.08594Z"
            fill="white"
          />
          <path
            id="Vector"
            d="M24.5222 48.4466C76.8389 50.6504 136.315 49.7915 192.858 48.7061C198.621 35.679 204.06 21.0453 209.446 4.07603C190.269 4.08015 170.665 4.14191 149.962 4.20988C101.114 4.36847 50.6363 4.53325 4.18749 3.87005C10.2078 21.5705 17.779 36.2742 24.5222 48.4466ZM99.6226 53.3156C73.3396 53.3156 47.5984 52.9366 23.3936 51.8986C22.788 51.8739 22.236 51.5319 21.9415 51.0026C14.7081 37.996 6.45303 22.0751 0.0867099 2.63014C-0.0883588 2.09258 0.00442115 1.50355 0.340141 1.04837C0.675861 0.59319 1.1928 0.296601 1.77361 0.337794C48.8671 1.04425 100.255 0.87534 149.95 0.712629C171.516 0.644661 191.919 0.613762 211.831 0.578748C212.387 0.578748 212.913 0.844457 213.24 1.2914C213.57 1.7404 213.666 2.31916 213.5 2.85054C207.7 21.3687 201.842 37.1639 195.599 51.1447C195.323 51.7626 194.716 52.1663 194.036 52.1807C162.915 52.7801 130.886 53.3156 99.6226 53.3156Z"
            fill="black"
          />
          <path
            id="Vector_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189.439 52.8234C188.648 52.8234 187.857 52.6443 187.141 52.3127C188.696 52.2838 190.249 52.2529 191.802 52.2241C191.07 52.6319 190.255 52.8234 189.439 52.8234ZM204.629 0.591121C205.263 0.218327 205.988 0 206.757 0C207.152 0 207.558 0.0576645 207.97 0.181243C208.336 0.282165 208.67 0.418116 208.973 0.582887C207.527 0.587006 206.081 0.589061 204.629 0.591121Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_3"
            d="M184.383 48.8643C184.294 48.3494 184.29 47.7933 184.393 47.2042C184.395 46.1373 185.069 45.264 185.511 44.3351L185.557 44.2301C185.718 43.8038 186.449 41.9954 186.809 41.326C189.958 34.7785 191.091 31.9383 192.786 28.0373C195.175 20.0336 196.11 17.9864 199.974 11.0104C201.818 7.99719 201.846 6.09411 202.269 5.00251L202.277 5.03752L202.275 4.98604L202.265 4.77183L202.267 4.63795C202.269 4.45053 202.281 4.26517 202.306 4.0798C204.693 4.07774 207.074 4.07568 209.447 4.07568C204.061 21.0449 198.621 35.6786 192.858 48.7057C190.041 48.7592 187.213 48.8128 184.383 48.8643Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_4"
            d="M187.141 52.3122C185.761 51.6716 184.659 50.4626 184.383 48.8643C187.213 48.8129 190.041 48.7593 192.858 48.7058C198.621 35.6786 204.061 21.0449 209.447 4.07565C207.074 4.07565 204.693 4.07771 202.306 4.07977C202.495 2.64215 203.387 1.31986 204.629 0.590754C206.081 0.588694 207.527 0.586639 208.973 0.58252C211.759 2.10253 211.947 6.09821 209.632 8.01779C209.368 8.48532 209.105 8.95284 208.853 9.42655C208.678 9.85702 208.458 10.4234 208.351 10.7694C207.206 14.1555 206.277 17.6095 205.014 20.9564C203.06 25.8439 202.104 27.7181 201.068 30.5995C200.485 32.342 200.637 31.9486 199.752 35.658C198.051 40.6773 196.522 45.9149 193.616 50.3905C193.142 51.2247 192.51 51.8261 191.802 52.2236C190.249 52.2525 188.696 52.2834 187.141 52.3122Z"
            fill="black"
          />
        </g>
        <g
          className={`${'mofu' === selectedTab ? 'mofu js__active' : 'mofu'} `}
        >
          <path
            id="fill_main"
            d="M31.0269 75.1061C82.4991 74.6942 133.971 74.2823 184.465 75.5016C179.815 85.4887 174.219 101.111 169.253 113.094C129.444 113.959 84.6185 114.824 45.1704 113.982C39.1089 101.232 34.1245 87.9129 31.0269 75.1061Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M46.2888 112.256C84.394 113.038 127.286 112.254 168.073 111.37C169.858 107.018 171.745 102.166 173.574 97.4635C176.338 90.3578 179.186 83.042 181.779 77.1886C132.872 76.062 82.285 76.445 33.2596 76.8363C36.1616 88.1252 40.5383 100.022 46.2888 112.256ZM80.3468 116.072C68.3001 116.072 56.5025 115.971 45.1334 115.73C44.4722 115.714 43.875 115.329 43.5928 114.731C37.14 101.163 32.341 87.9687 29.3278 75.5182C29.2021 75.0013 29.3216 74.4534 29.6491 74.0311C29.9786 73.611 30.4812 73.3638 31.0147 73.3576C81.6404 72.954 133.988 72.5359 184.509 73.7531C185.098 73.7675 185.641 74.0786 185.95 74.579C186.261 75.0816 186.298 75.7056 186.051 76.2411C183.221 82.315 179.975 90.6606 176.834 98.7323C174.849 103.836 172.795 109.113 170.868 113.763C170.602 114.404 169.984 114.826 169.29 114.843C139.916 115.481 109.41 116.072 80.3468 116.072Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M57.9089 108.942L57.5834 108.843C53.3179 105.97 49.1287 102.862 45.1371 99.6075C44.8858 99.4036 44.8487 99.037 45.0526 98.7878C45.2545 98.5345 45.6232 98.5015 45.8724 98.7033C49.8393 101.937 53.9976 105.022 58.2343 107.875C58.502 108.054 58.5721 108.419 58.3929 108.684C58.2796 108.851 58.0943 108.942 57.9089 108.942Z"
            fill="black"
          />
          <path
            id="Vector_7"
            d="M73.319 108.408L72.9544 108.281C62.8931 100.24 52.529 92.263 42.1485 84.5764C41.8911 84.3849 41.8355 84.0203 42.0291 83.7608C42.2206 83.5033 42.5852 83.4477 42.8427 83.6393C53.2356 91.3361 63.612 99.3192 73.6835 107.37C73.9348 107.57 73.974 107.937 73.7742 108.188L73.319 108.408Z"
            fill="black"
          />
          <path
            id="Vector_8"
            d="M88.4 108.324L88.0416 108.201C76.5777 99.2415 65.0457 90.0266 53.7692 80.8077C53.52 80.6038 53.483 80.2372 53.6869 79.988C53.8908 79.7387 54.2573 79.7017 54.5065 79.9056C65.7789 89.1184 77.3026 98.3291 88.7583 107.284C89.0116 107.482 89.0569 107.847 88.8592 108.1L88.4 108.324Z"
            fill="black"
          />
          <path
            id="Vector_9"
            d="M102.992 107.753L102.605 107.607C92.9536 99.0247 83.2959 90.193 73.8998 81.3551C73.6671 81.1326 73.6548 80.764 73.8752 80.5292C74.0976 80.2964 74.4662 80.2861 74.6989 80.5045C84.0868 89.3382 93.7382 98.1638 103.379 106.734C103.62 106.948 103.641 107.317 103.427 107.558L102.992 107.753Z"
            fill="black"
          />
          <path
            id="Vector_10"
            d="M118.845 107.093L118.427 106.916C109.743 97.9583 100.776 89.0339 91.7751 80.3958C91.5403 80.1734 91.5341 79.8047 91.7565 79.574C91.979 79.3392 92.3477 79.3309 92.5804 79.5554C101.593 88.2039 110.571 97.1345 119.265 106.106C119.487 106.337 119.481 106.706 119.251 106.93L118.845 107.093Z"
            fill="black"
          />
          <path
            id="Vector_11"
            d="M136.632 107.069L136.193 106.87C128.367 97.8855 120.029 89.1569 111.418 80.9246C111.185 80.7022 111.177 80.3335 111.4 80.0987C111.622 79.868 111.991 79.8598 112.223 80.0822C120.861 88.3372 129.222 97.0925 137.073 106.105C137.285 106.346 137.258 106.715 137.015 106.927L136.632 107.069Z"
            fill="black"
          />
          <path
            id="Vector_12"
            d="M152.31 106.909L151.882 106.722C143.621 97.7398 134.946 88.9472 126.099 80.583C125.867 80.3626 125.856 79.994 126.077 79.7592C126.297 79.5285 126.666 79.5141 126.901 79.7365C135.767 88.1193 144.461 96.9304 152.741 105.931C152.957 106.168 152.943 106.537 152.706 106.755L152.31 106.909Z"
            fill="black"
          />
          <path
            id="Vector_13"
            d="M166.035 102.946L165.625 102.775C158.454 95.6345 151.336 88.2466 144.465 80.8196C144.246 80.5827 144.261 80.2141 144.496 79.9958C144.735 79.7774 145.101 79.7939 145.32 80.0287C152.18 87.4433 159.288 94.8188 166.447 101.949C166.676 102.178 166.676 102.547 166.449 102.773L166.035 102.946Z"
            fill="black"
          />
          <path
            id="Vector_14"
            d="M171.168 89.4124L170.773 89.2579L161.836 80.9925C161.601 80.7742 161.586 80.4055 161.805 80.1687C162.023 79.9318 162.39 79.9195 162.629 80.1378L171.563 88.4011C171.8 88.6194 171.815 88.9901 171.596 89.2249L171.168 89.4124Z"
            fill="black"
          />
          <path
            id="Vector_15"
            d="M173.436 79.7696C173.512 79.5842 173.59 79.3988 173.669 79.2155C173.631 79.3123 173.592 79.4092 173.553 79.5039L173.436 79.7696ZM173.669 79.2155C173.737 79.0405 173.807 78.8654 173.887 78.6965C173.815 78.8695 173.743 79.0425 173.669 79.2155Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_16"
            d="M165.926 116.005C164.814 116.005 163.696 115.653 162.81 114.983C164.911 114.938 167.009 114.892 169.1 114.847C168.221 115.63 167.078 116.005 165.926 116.005Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_17"
            d="M161.01 111.523C160.932 109.914 161.842 108.427 162.503 106.954C163.214 105.232 163.891 103.496 164.635 101.789C164.964 102.118 165.294 102.448 165.626 102.775L166.035 102.946L166.449 102.773C166.676 102.547 166.676 102.178 166.447 101.949C166.013 101.517 165.578 101.084 165.144 100.65C165.42 100.05 165.706 99.4572 166.009 98.8723C167.061 96.547 168.768 92.23 170.218 88.7451L170.772 89.2579L171.168 89.4124L171.596 89.225C171.815 88.9902 171.8 88.6194 171.563 88.4011L170.7 87.602C170.952 87.0088 171.193 86.4527 171.415 85.9522C172.171 83.8102 172.676 81.596 173.553 79.5035C173.909 78.7105 174.206 77.8454 174.577 77.0339C176.982 77.0813 179.382 77.1328 181.779 77.1884C179.186 83.0419 176.338 90.3578 173.574 97.4635C171.745 102.166 169.858 107.018 168.072 111.37C165.724 111.422 163.37 111.471 161.01 111.523Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_18"
            d="M162.81 114.983C162.038 114.396 161.444 113.564 161.189 112.509C161.082 112.174 161.026 111.846 161.01 111.523C163.37 111.471 165.724 111.422 168.072 111.37C169.858 107.018 171.745 102.166 173.573 97.4638C176.337 90.358 179.186 83.0422 181.779 77.1888C179.382 77.1332 176.982 77.0816 174.577 77.0343C175.054 75.9921 175.656 75.0426 176.68 74.4515C177.359 74.0581 178.146 73.8645 178.929 73.8645C180.284 73.8645 181.631 74.4453 182.414 75.5946C183.186 76.4555 183.534 77.749 183.287 78.8777C183.276 79.1187 183.225 79.3452 183.163 79.5717L183.198 79.4894L183.163 79.5759C183.085 79.8725 182.968 80.1608 182.805 80.4347C182.463 81.4357 180.278 86.3129 179.829 87.1985C178.111 91.946 177.071 96.9324 175.359 101.69C173.769 105.758 171.881 109.71 170.031 113.665C169.778 114.13 169.46 114.524 169.1 114.847C167.009 114.892 164.911 114.938 162.81 114.983Z"
            fill="black"
          />
          <path
            id="Vector_19"
            d="M166.035 102.946L165.625 102.775C165.294 102.448 164.964 102.118 164.635 101.789C164.802 101.408 164.97 101.027 165.143 100.65C165.578 101.084 166.013 101.517 166.447 101.949C166.676 102.178 166.676 102.547 166.449 102.773L166.035 102.946Z"
            fill="black"
          />
          <path
            id="Vector_20"
            d="M171.168 89.4125L170.773 89.258L170.219 88.7452C170.383 88.3538 170.544 87.9707 170.7 87.6021L171.564 88.4012C171.8 88.6195 171.815 88.9903 171.596 89.2251L171.168 89.4125Z"
            fill="black"
          />
        </g>
        <g
          className={`${'bofu' === selectedTab ? 'bofu js__active' : 'bofu'} `}
        >
          <path
            id="fill_main"
            d="M60.7285 135.998C91.833 136.447 122.937 136.898 154.194 137.936C148.858 147.626 144.104 157.64 138.815 168.851C117.3 168.942 95.7855 168.935 74.5033 167.675C68.7796 157.129 63.6594 146.255 60.7285 135.998Z"
            fill="white"
          />
          <path
            id="Vector_21"
            d="M75.5786 165.986C95.9545 167.156 116.604 167.195 137.705 167.108C142.749 156.421 146.829 147.902 151.303 139.589C121.797 138.642 91.9938 138.197 63.091 137.779C65.6326 145.871 69.7354 155.136 75.5786 165.986ZM125.494 170.634C108.226 170.634 91.2359 170.418 74.3984 169.421C73.7949 169.386 73.2553 169.04 72.9669 168.511C66.1949 156.029 61.641 145.552 59.048 136.477C58.8955 135.946 59.0048 135.373 59.3405 134.933C59.6762 134.494 60.2096 134.251 60.7533 134.249L60.9326 134.251C91.4666 134.694 123.039 135.151 154.252 136.187C154.86 136.208 155.414 136.541 155.715 137.071C156.015 137.6 156.02 138.244 155.727 138.778C150.609 148.069 146.146 157.411 140.397 169.596C140.111 170.208 139.497 170.597 138.824 170.599C134.363 170.62 129.918 170.634 125.494 170.634Z"
            fill="black"
          />
          <path
            id="Vector_22"
            d="M134.91 171.93C133.703 171.93 132.49 171.479 131.633 170.626C133.779 170.62 135.93 170.611 138.084 170.603C137.254 171.501 136.084 171.93 134.91 171.93Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_23"
            d="M130.464 167.131C130.682 165.131 132.105 163.284 132.82 161.42C135.635 154.821 139.211 148.596 142.686 142.329C143.268 141.417 143.734 140.329 144.331 139.375C146.658 139.446 148.98 139.516 151.303 139.59C146.829 147.902 142.749 156.421 137.705 167.108C135.285 167.119 132.871 167.127 130.464 167.131Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_24"
            d="M131.634 170.626C131.135 170.128 130.756 169.491 130.573 168.729C130.431 168.183 130.406 167.652 130.464 167.131C132.871 167.127 135.285 167.119 137.705 167.108C142.749 156.421 146.83 147.902 151.303 139.59C148.98 139.515 146.659 139.445 144.331 139.375C144.955 138.381 145.724 137.53 146.916 137.176C147.235 137.077 147.563 137.031 147.886 137.031C150.034 137.031 152.078 139.081 151.608 141.301C151.61 142.135 151.066 142.798 150.7 143.509C149.567 145.783 148.428 148.053 147.359 150.357C145.499 154.691 142.16 162.149 138.669 169.786C138.503 170.093 138.305 170.365 138.084 170.603C135.93 170.612 133.78 170.62 131.634 170.626Z"
            fill="black"
          />
        </g>
        <g id="result">
          <path
            id="Vector_25"
            d="M78.5586 219.404C78.5586 203.473 91.1985 190.557 106.79 190.557C122.381 190.557 135.021 203.473 135.021 219.404C135.021 235.337 122.381 248.251 106.79 248.251C91.1985 248.251 78.5586 235.337 78.5586 219.404Z"
            fill="white"
          />
          <path
            id="Vector_26"
            d="M106.79 192.305C92.1872 192.305 80.3073 204.461 80.3073 219.404C80.3073 234.346 92.1872 246.502 106.79 246.502C121.393 246.502 133.273 234.346 133.273 219.404C133.273 204.461 121.393 192.305 106.79 192.305ZM106.79 250C90.2594 250 76.8101 236.276 76.8101 219.404C76.8101 202.533 90.2594 188.808 106.79 188.808C123.321 188.808 136.77 202.533 136.77 219.404C136.77 236.276 123.321 250 106.79 250Z"
            fill="black"
          />
          <path
            id="Vector_27"
            d="M106.716 234.46C101.977 234.46 97.2171 232.833 93.551 229.834C92.8033 229.222 92.6942 228.12 93.3038 227.373C93.9176 226.627 95.0175 226.516 95.7651 227.128C99.8473 230.468 105.559 231.756 110.681 230.487C112.084 230.139 113.027 229.688 113.653 229.066C115.241 227.482 114.72 224.458 113.189 222.813C111.202 220.679 107.989 220.082 105.645 219.956C105.116 219.927 104.584 219.917 104.053 219.906C102.543 219.876 100.984 219.845 99.4044 219.451C96.5992 218.753 94.4489 216.897 93.7898 214.609C93.3676 213.141 93.5324 211.441 94.2553 209.827C95.8103 206.34 99.5919 203.866 104.123 203.376C107.946 202.962 111.756 203.891 115.111 204.71C116.048 204.941 116.623 205.886 116.394 206.826C116.164 207.763 115.216 208.335 114.279 208.107C111.056 207.32 107.721 206.502 104.498 206.852C101.252 207.205 98.4837 208.931 97.4477 211.252C97.0605 212.117 96.9555 212.965 97.1512 213.643C97.526 214.949 99.0543 215.76 100.249 216.057C101.448 216.356 102.747 216.382 104.123 216.409C104.694 216.422 105.262 216.434 105.83 216.463C110.117 216.691 113.546 218.063 115.75 220.432C118.425 223.307 119.214 228.458 116.12 231.543C114.685 232.973 112.808 233.562 111.519 233.881C109.95 234.27 108.335 234.46 106.716 234.46Z"
            fill="black"
          />
          <path
            id="Vector_28"
            d="M104.71 242.091L104.609 242.089C103.645 242.033 102.91 241.207 102.963 240.243L105.406 196.958C105.462 195.996 106.286 195.269 107.252 195.31C108.215 195.366 108.953 196.192 108.899 197.156L106.455 240.441C106.401 241.37 105.631 242.091 104.71 242.091Z"
            fill="black"
          />
          <path
            id="Vector_29"
            d="M106.79 246.503C102.712 246.503 98.8485 245.555 95.3945 243.864C95.444 242.799 95.9218 241.761 96.9351 241.055C97.6766 240.309 98.766 240.276 99.7485 240.134C101.332 239.903 102.296 239.788 102.992 239.706L102.964 240.243C102.91 241.207 103.645 242.033 104.609 242.089L104.71 242.091C105.631 242.091 106.401 241.37 106.455 240.441L106.531 239.096C111.021 237.965 116.296 234.985 118.028 233.622C121.541 230.785 123.873 227.914 124.925 226.147C126.021 224.617 126.495 222.522 128.182 221.511C128.802 221.14 129.492 220.967 130.178 220.967C131.271 220.967 132.355 221.404 133.135 222.164C132.736 226.197 131.467 229.97 129.527 233.286C129.092 233.782 128.416 234.54 127.286 235.815C123.078 240.295 117.787 243.767 112.045 245.967C110.345 246.319 108.588 246.503 106.79 246.503Z"
            fill="#DEDEDE"
          />
          <path
            id="Vector_30"
            d="M99.2375 248.157C96.8463 248.157 95.2934 245.957 95.3943 243.865C98.8483 245.556 102.712 246.503 106.79 246.503C108.588 246.503 110.345 246.32 112.044 245.968C111.148 246.311 110.24 246.625 109.324 246.905C106.803 247.564 104.238 247.533 99.3467 248.157H99.2375ZM129.526 233.286C131.466 229.97 132.735 226.197 133.135 222.165C133.627 222.642 133.998 223.246 134.175 223.95C134.778 226.16 133.244 228.216 132.09 229.975C130.132 232.518 130.702 231.95 129.526 233.286Z"
            fill="black"
          />
          <path
            id="Vector_31"
            d="M104.71 242.091L104.609 242.089C103.645 242.033 102.91 241.207 102.963 240.243L102.992 239.706C103.76 239.615 104.205 239.562 104.805 239.438C105.359 239.362 105.938 239.244 106.531 239.096L106.454 240.441C106.401 241.37 105.631 242.091 104.71 242.091Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  )
}
