import React from 'react'
import { Link } from 'gatsby'

// HELPERS
import cls from '../../helpers/classNamesRendering'

export const BtnPrimary = ({ className, ctaText, ctaLink, onClick }) => {
  return (
    <Link
      onClick={onClick}
      to={ctaLink}
      className={cls('btn btn--primary', className)}
    >
      {ctaText}
    </Link>
  )
}

export const BtnSecondary = ({ ctaText }) => {
  return (
    <span className="btn--secondary">
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="text">{ctaText}</span>
    </span>
  )
}
