import * as React from 'react'

// COMPONENTS
import { LinksContact } from '../LinksCollection/LinksCollection'

// HELPERS
import formValidation from '../../helpers/formValidation'

// CUSTOM HOOKS
import useGetCurrentPath from '../../hooks/useGetCurrentPath'

// GLOBAL DATA
import contactData from '../../global/contactData'

export const Form = ({ contactInfo, contactFormData }) => {
  const currentPath = useGetCurrentPath()

  return (
    <section className="contact">
      <div className="grid">
        <div className="contact__details grid">
          <h1 className="heading--secondary">{contactInfo.title}</h1>
          <div className="contact__hint">
            {contactInfo &&
              contactInfo.copy.map((item) => (
                <p className="copy">{item.text}</p>
              ))}
          </div>
          <LinksContact links={contactData} />
        </div>
        <form
          name="Contact Form"
          action={currentPath}
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          className="contact__form form grid"
        >
          <input type="hidden" name="subject" value="Kontaktanfrage" />

          <h2 className="heading--secondary">{contactFormData.title}</h2>
          <div className="grid">
            <div className="row grid">
              <label htmlFor="name">Dein Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Dagobert Duck"
                required
                aria-required
              />
            </div>
            <div className="row grid">
              <label htmlFor="mail">E-Mail</label>
              <input
                type="email"
                id="mail"
                name="mail"
                placeholder="moreperformance@with-contective.com"
                required
                aria-required
              />
            </div>
            <div className="row grid">
              <label htmlFor="phone">Telefonnummer</label>
              <input
                type="phone"
                id="phone"
                name="phone"
                placeholder="+49 123 456 789"
                required
                aria-required
              />
            </div>
            <div className="row grid">
              <label htmlFor="services">Wähle dein Anliegen aus</label>
              <select name="services" id="services" aria-required required>
                {contactFormData &&
                  contactFormData.options.map((item) => (
                    <option value={item.option}>{item.option}</option>
                  ))}
                <option value="Alle">Alle</option>
              </select>
            </div>
            <div className="row grid">
              <label htmlFor="url">Website</label>
              <input
                type="url"
                name="url"
                id="url"
                placeholder="www.example.xyz"
              />
            </div>
            <div className="row grid">
              <label htmlFor="message">Nachricht</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Erzähl uns was über dein Anliegen"
              ></textarea>
            </div>
            <input type="hidden" name="form-name" value="Contact Form" />
            <div className="row grid hidden">
              <label htmlFor="bot-field">Additional Data</label>
              <input
                type="text"
                name="bot-field"
                id="bot-field"
                aria-hidden="true"
              />
            </div>
            <button type="submit" className="btn btn--primary">
              {contactFormData.ctaText}
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}
