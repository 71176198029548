import { Builder } from '@builder.io/react'
import { Marquee } from './Marquee'

Builder.registerComponent(Marquee, {
  name: 'Marquee',
  inputs: [
    {
      name: 'banner',
      type: 'list',
      defaultValue: [{ item: '' }],
      subFields: [
        {
          name: 'item',
          type: 'string'
        }
      ]
    }
  ]
})
