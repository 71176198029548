import * as React from 'react'

// COMPONENTS
import IconPDF from '../../svgs/icon_pdf.inline.svg'

// HELPERS
import formValidation from '../../helpers/formValidation'

export const JobForm = ({ jobs }) => {
  const [file, setFile] = React.useState({})

  React.useEffect(() => {
    formValidation()
    validateFileType(file)
    updatePreview(file)
  }, [file])

  function updateUploadState(event) {
    setFile(() => event.target.files[0])
  }

  function updatePreview(file) {
    const hint = document.querySelector('.dropzone__hint')
    const preview = document.querySelector('.dropzone__preview-text')

    if (file === undefined || file?.constructor !== File) {
      hint.textContent = ''
      preview.textContent = ''
    } else if (!validateFileType(file)) {
      hint.textContent = 'Falscher Dateityp. Bitte eine PDF Datei hochladen.'
    } else {
      hint.textContent = `1 Datei hochgeladen, ${returnFileSize(file.size)}`
      preview.textContent = file.name
    }
  }

  function validateFileType(upload) {
    if (upload === undefined) {
      return false
    }
    const fileType = ['application/pdf']
    return fileType.includes(upload.type)
  }

  function returnFileSize(number) {
    if (number < 1024) {
      return number + 'bytes'
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + 'KB'
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + 'MB'
    }
  }

  return (
    <div className="jobform grid">
      <h2 className="heading--primary">Schick uns deine Bewerbung</h2>
      <p className="copy">
        Wir freuen uns von dir zu hören. Asap werden wir dir antworten. Eine
        Bitte noch: Bewirb dich auf die Stelle, in der du deine Talente am
        besten zur Entfaltung bringen kannst.
      </p>
      <form
        name="Application Form"
        action=""
        method="POST"
        data-netlify="true"
        className="job__form form grid"
        encType="multipart/form-data"
      >
        <input type="hidden" name="subject" value="Bewerbung" />
        <div className="grid">
          <div className="row grid">
            <label htmlFor="name">Dein Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Dagobert Duck"
              required
              aria-required
            />
          </div>
          <div className="row grid">
            <label htmlFor="mail">E-Mail</label>
            <input
              type="email"
              id="mail"
              name="mail"
              placeholder="moreperformance@with-contective.com"
              required
              aria-required
            />
          </div>
          <div className="row grid">
            <label htmlFor="phone">Telefonnummer</label>
            <input
              type="phone"
              id="phone"
              name="phone"
              placeholder="+49 123 456 789"
              required
              aria-required
            />
          </div>
          <div className="row grid">
            <label htmlFor="application">Wähle dein Talent aus</label>
            <select name="application" id="application" required aria-required>
              {jobs.map((job) => (
                <option value={job.jobTitle}>{job.jobTitle}</option>
              ))}
            </select>
          </div>
          <div className="row grid">
            <label htmlFor="message">Deine Nachricht</label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              placeholder="Sag uns in 3 Worten, warum du der perfekte Fit bist für unser Team!"
              required
              aria-required
            ></textarea>
          </div>
          <div className="row grid dropzone">
            <label htmlFor="file">Upload Bewerbung</label>
            <div className="dropzone__container">
              <input
                accept="application/pdf"
                aria-required
                className="dropzone__input"
                data-inputText="Upload"
                id="file"
                name="file"
                onChange={updateUploadState}
                required
                title="Lade bitte deine Bewerbung hier als PDF hoch"
                type="file"
              />
              <div className="dropzone__custom-input flex-col">
                <p>
                  Wir bitten dich, deine Bewerbung als <IconPDF /> PDF
                  hochzuladen.
                </p>
                <p className="dropzone__preview flex-row">
                  <span>Dein Upload:</span>

                  <span className="dropzone__preview-text"></span>
                </p>
                <span className="dropzone__hint"></span>
              </div>
            </div>
          </div>

          <input type="hidden" name="form-name" value="Application Form" />
          <button type="submit" className="btn btn--primary">
            Abschicken
          </button>
        </div>
      </form>
    </div>
  )
}
