import * as React from 'react'

export const Theme = (props) => {
  const { colors } = props

  React.useEffect(() => {
    updateCustomPropertyValue(colors)
  }, [colors])

  function convertStringToCSSPropertyFormat(string, prefix) {
    const newString = `--${prefix}-${string}`
    return newString
  }

  function updateCustomPropertyValue(theme) {
    for (let [key, value] of Object.entries(theme)) {
      document.documentElement.style.setProperty(
        convertStringToCSSPropertyFormat(`${key}`, 'clr'),
        `${value}`
      )
    }
  }
  return <div className="theme">{props.children}</div>
}
