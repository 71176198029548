import * as React from 'react'

export const Slogan = ({ children }) => {
  return <div className="slogan flex-col">{children}</div>
}

/* TODO:
<Slogan>
        <p ref={ref} className={inView ? 'js__active' : ''}>
          {slogan}
          <span>{subSlogan}</span>
        </p>
      </Slogan>
*/
