import * as React from 'react'
import { Image } from '@builder.io/react'

// COMPONENTS
import { Video } from '../Media/Video'

export const MediaType = ({ media, index }) => {
  const { mediaType, mediaFile, playbackRate, imageAltText } = media

  if (mediaType === 'img') {
    return <Image src={mediaFile} altText={imageAltText || ''} />
  } else if (mediaType === 'video') {
    return (
      <Video
        src={mediaFile}
        autoplay={false}
        className={'illustration__video'}
        id={`video-${index}`}
        playbackrate={playbackRate}
      />
    )
  }
}
