import { Builder, withChildren } from '@builder.io/react'
import { Stats } from './Stats'
import { Stat } from './Stat'

const StatsWithChildren = withChildren(Stats)

Builder.registerComponent(StatsWithChildren, {
  name: 'Stats',
  inputs: [
    {
      name: 'stats',
      type: 'list',
      defaultValue: [{ stat: '' }],
      subFields: [
        {
          name: 'stat',
          type: 'string',
          defaultValue: ''
        },
        { name: 'number', type: 'string', defaultValue: '' },
        {
          name: 'suffix',
          type: 'string',
          helperText: 'Leerlassen, falls Zahlenwert keine Einheit benötigt'
        },
        {
          name: 'detail',
          type: 'string',
          defaultValue: ''
        }
      ]
    },
    {
      name: 'duration',
      type: 'number',
      friendlyName: 'Duration Counter Animation'
    }
  ]
})

Builder.registerComponent(Stat, {
  name: 'Stat',
  inputs: []
})
