import { Builder } from '@builder.io/react'
import { Impressum } from './Impressum'
import { DataPolicy } from './DataPolicy'

Builder.registerComponent(Impressum, {
  name: 'Impressum',
  inputs: []
})

Builder.registerComponent(DataPolicy, {
  name: 'DataPolicy',
  inputs: []
})
