import * as React from 'react'
import { useInView } from 'react-intersection-observer'

// HELPERS
import cls from '../../helpers/classNamesRendering'

export const Video = (props) => {
  const { className, id, src, poster, autoplay, playbackrate } = props
  const [videoActive, setVideoActive] = React.useState(!autoplay)
  const { inView, ref } = useInView()

  const videoProps = {
    loop: true,
    muted: true,
    playsInline: true,
    poster: poster
  }

  if (autoplay) {
    videoProps.autoPlay = autoplay
  }

  function controlVideoState() {
    setVideoActive((state) => !state)
    customControls()
  }

  React.useEffect(() => {
    const videoAPI = document.querySelector(`#${id} .video__element`)
    videoAPI.playbackRate = `${playbackrate ?? 1}`

    if (!autoplay && inView) {
      videoAPI.play()
    } else if (!autoplay && !inView) {
      videoAPI.pause()
    }
  }, [inView])

  function customControls() {
    const videoAPI = document.querySelector(`#${id} .video__element`)

    if (videoActive) {
      videoAPI.pause()
    } else {
      videoAPI.play()
    }
  }

  return (
    <div ref={ref} className={cls('video', className)} id={id}>
      <button
        className="video__custom-controls"
        onClick={controlVideoState}
        aria-label={videoActive ? 'Pausiere das Video' : 'Starte das Video'}
      >
        <span>
          <svg
            className={videoActive ? 'video__icon' : 'video__icon js__active'}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.82479 1.27716L16.1948 9.99966L1.80542 18.7228L1.82479 1.27716ZM1.82479 0.0271611C1.61228 0.0269894 1.40325 0.0812025 1.21761 0.184641C0.820732 0.404954 0.574795 0.823411 0.574795 1.27716L0.55542 18.7228C0.555356 18.9457 0.614929 19.1645 0.72796 19.3566C0.840991 19.5487 1.00336 19.7071 1.19823 19.8153C1.38393 19.9186 1.59292 19.9728 1.80542 19.9728C2.03417 19.9728 2.26386 19.91 2.46509 19.7841L16.8542 11.0609C17.0348 10.949 17.1839 10.7927 17.2874 10.607C17.3908 10.4213 17.4451 10.2122 17.4451 9.99968C17.4451 9.5678 17.2217 9.16624 16.8542 8.93812L2.48448 0.215306C2.2864 0.0923893 2.05793 0.0272338 1.82481 0.0271806L1.82479 0.0271611Z"
              fill="currentColor"
            />
          </svg>
          <svg
            className={videoActive ? 'video__icon js__active' : 'video__icon'}
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.13028 0H0.751221C0.406221 0 0.126221 0.279687 0.126221 0.625V19.375C0.126221 19.72 0.406221 20 0.751221 20H5.13028C5.4756 20 5.75528 19.72 5.75528 19.375V0.625C5.75528 0.279687 5.4756 0 5.13028 0ZM4.50528 18.75H1.37622V1.25H4.50528V18.75ZM13.2487 0H8.89218C8.54686 0 8.26718 0.279687 8.26718 0.625V19.375C8.26718 19.72 8.54686 20 8.89218 20H13.2487C13.5941 20 13.8737 19.72 13.8737 19.375V0.625C13.8737 0.279687 13.5944 0 13.2487 0ZM12.6237 18.75H9.51718V1.25H12.6237V18.75Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </button>
      <video {...videoProps} className="video__element">
        <source src={src} type="video/mp4" />
        <span className="video__fallback">
          Ups, scheint als würde dein Browser nicht das geeignete Videoformat
          (.mp4) unterstützen. Versuche es mit einem anderem Browser oder update
          deinen Browser.
        </span>
      </video>
    </div>
  )
}
