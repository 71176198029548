import * as React from 'react'

// COMPONENTS
import IconPaperPlane from '../../svgs/icon_paperplane.inline.svg'

export const InputWithSubmitBtn = ({
  ariaLabel,
  copy,
  htmlFor,
  label,
  placeholder,
  type,
  onChange
}) => {
  return (
    <div className="input__submit-included">
      <label htmlFor={htmlFor}>{label}</label>
      {copy ? <p>{copy}</p> : null}
      <div className="input-field">
        <input
          type={type}
          name={htmlFor}
          id={htmlFor}
          placeholder={placeholder}
          required
          aria-required
          onChange={onChange}
        />
        <button type="submit" aria-label={ariaLabel} title={ariaLabel}>
          <IconPaperPlane />
        </button>
      </div>
    </div>
  )
}
