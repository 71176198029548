import { Builder } from '@builder.io/react'
import { VerticalSlider } from './VerticalSlider'

Builder.registerComponent(VerticalSlider, {
  name: 'Vertical Slider',
  noWrap: true,
  inputs: [
    {
      name: 'content',
      type: 'list',
      defaultValue: [{ section: '' }],
      subFields: [
        {
          name: 'category',
          type: 'string'
        },
        {
          name: 'headline',
          type: 'string'
        },
        {
          name: 'copy',
          type: 'longText'
        }
      ]
    }
  ]
})
