import { Builder } from '@builder.io/react'
import { Navbar } from './Navbar'
import { StickyCTA } from './StickyCTA'

Builder.registerComponent(Navbar, {
  name: 'Navbar',
  inputs: [
    {
      name: 'ctaText',
      type: 'string'
    }
  ]
})

Builder.registerComponent(StickyCTA, {
  name: 'Sticky CTA',
  inputs: [
    {
      name: 'CTA Text',
      type: 'string'
    }
  ]
})
