import { Builder } from '@builder.io/react'
import { Vision } from './Vision'

Builder.registerComponent(Vision, {
  name: 'Vision',
  inputs: [
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'subheading',
      type: 'longText'
    },
    {
      name: 'explanations',
      type: 'list',
      defaultValue: [{ title: '' }],
      subFields: [
        { name: 'title', type: 'string' },
        {
          name: 'explanation',
          type: 'longText'
        }
      ]
    }
  ]
})
