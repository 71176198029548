function formValidation() {
  const inputs = document.querySelectorAll(
    'input[required]',
    'select[required]',
    'textarea[required]'
  )

  inputs.forEach((input) => {
    input.addEventListener('invalid', addInvalidClass(input), false)
  })
}

function addInvalidClass(input) {
  input.classList.add('invalid')
}

export default formValidation
