import React, { useState } from 'react'
import { Link } from 'gatsby'

// COMPONENTS
import { BtnPrimary } from '../Buttons/Buttons.jsx'
import { BurgerMenu } from './BurgerMenu.jsx'
import { StickyCTA } from './StickyCTA.jsx'

export const Navbar = ({ ctaText }) => {
  const [opened, setOpened] = useState(false)

  const closeMobileMenu = () => {
    if (opened) {
      setOpened(false)
    } else return
  }

  const links = [
    { id: '0', name: 'Home', url: '/' },
    { id: '3', name: 'Vision', url: '/vision' },
    { id: '2', name: 'Über uns', url: '/about' },
    { id: '1', name: 'Jobs', url: '/jobs' }
  ]

  return (
    <nav className="nav--primary">
      <BurgerMenu opened={opened} setOpened={setOpened} />

      <ul className="nav__bar flex-row" role="list">
        {links.map((link) => (
          <li key={link.id}>
            <Link
              to={link.url}
              activeClassName="link__active"
              className="nav__link"
              onClick={() => closeMobileMenu()}
            >
              {link.name}
            </Link>
          </li>
        ))}
        <li>
          <BtnPrimary
            onClick={() => closeMobileMenu()}
            ctaLink={'/contact'}
            ctaText={ctaText}
          />
        </li>
      </ul>
      <StickyCTA />
    </nav>
  )
}
