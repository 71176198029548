import * as React from 'react'
import { Link } from 'gatsby'
import { Email } from 'react-obfuscate-email'

// COMPONENTS
import { Title } from '../HeadingSection/Title'
import phoneNumberReadableFormat from '../../helpers/phoneNumberReadableFormat'

export const Impressum = () => {
  const contactPhone = '+491784602412'
  const contactMail = 'hello@contective.digital'

  return (
    <section className="legal impressum wrapper grid">
      <Title as={'h1'} pageHeading={true}>
        {'Impressum'}
      </Title>
      <div className="impressum__info">
        <h2 className="heading--secondary">Angaben gemäß § 5 TMG</h2>
        <p>Ecom Brands Ventures GmbH</p>
        <p>Taunusstraße 52</p>
        <p>D-65830 Kriftel</p>
      </div>
      <div className="impressum__contact">
        <h2 className="heading--secondary">Dein Kontakt zu uns</h2>
        <p>Dein Ansprechpartner: Andreas Klinkenberg</p>
        <p className="impressum__mail">
          E-Mail:
          <Email email={contactMail} />
        </p>
        <p className="impressum__contact-form">
          Oder besuche unser <Link to="/contact">Kontakformular</Link>.
        </p>
        <p className="impressum__phone">
          Wir sind auch telefonisch zu erreichen:
        </p>
        <div className="impressum__contact-details grid">
          <a href={`tel:${contactPhone}`}>
            Mobil: {phoneNumberReadableFormat(contactPhone)}
          </a>
        </div>
      </div>
      <div className="impressum__tax">
        <h2 className="heading--secondary">Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
        </p>
        <p>USt.-IdNr.: DE363050477</p>
        <p>Steuer-Nr.: 040 232 08795</p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit, die Sie hier finden:{' '}
          <a
            href="http://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noreferrer noopener"
          >
            EU Streitschlichtung
          </a>
          . Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Verbraucherstreitbeilegung/Universalschlichtungsstelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Haftung für Inhalte</h2>
        <p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich.
        </p>
        <p>
          Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Haftung für Links</h2>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </section>
  )
}
