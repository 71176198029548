import * as React from 'react'

// COMPONENTS
import { Title } from '../HeadingSection/Title'
import VisionSchema from '../../svgs/vision_schema.inline.svg'

export const Vision = ({ title, subheading, explanations }) => {
  return (
    <section className="vision">
      <div className="vision__intro wrapper">
        <Title as="h1" pageHeading={true} subheading={subheading}>
          {title}
        </Title>
      </div>
      <div className="vision__visual flex-row">
        <VisionSchema />
      </div>
      <div className="vision__explanations wrapper">
        <ul role="list" className="vision__explanation grid">
          {explanations.map((copy, index) => (
            <li key={index} className="grid">
              <h3 className="heading--secondary">{copy.title}</h3>
              <p className="copy">{copy.explanation}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
