import * as React from 'react'

// COMPONENTS
import { HeadingSection } from '../HeadingSection/HeadingSection'
import { Marquee } from '../Marquee/Marquee'
import { ServicesCards } from './ServicesCards'

export const Services = ({ banner, title, subtitle, services }) => {
  return (
    <section className="services">
      <Marquee banner={banner} />
      <div className="services__content">
        <HeadingSection title={title} subheading={subtitle} />
        <div className="services__cards">
          <ServicesCards services={services} />
        </div>
      </div>
    </section>
  )
}
