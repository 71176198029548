import * as React from 'react'
import { Email } from 'react-obfuscate-email'

// COMPONENTS
import { Title } from '../HeadingSection/Title'

export const DataPolicy = () => {
  return (
    <section className="legal privacy wrapper grid">
      <Title as={'h1'} pageHeading={true}>
        {'Datenschutz'}
      </Title>
      <div className="privacy__info">
        <p>
          Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
          der EU Datenschutzgrundverordnung (DSGVO), ist:
        </p>
        <p>Contective</p>
        <p>Ecom Brands Ventures GmbH</p>
        <p>Taunusstraße 52</p>
        <p>D-65830 Kriftel</p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Ihre Betroffenenrechte</h2>
        <p>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </p>
        <ul role="list" className="marker">
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung,
          </li>
          <li>Berichtigung unrichtiger personenbezogener Daten,</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen,
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
            jederzeit mit Wirkung für die Zukunft widerrufen.
          </li>
        </ul>
        <p>
          Sie können sich jederzeit mit einer Beschwerde an die für Sie
          zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde
          richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder
          der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den
          nichtöffentlichen Bereich) mit Anschrift finden Sie unter:{' '}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html."
            target="_blank"
            rel="noopener noreferrer"
          >
            Liste Aufsichtsbehörden
          </a>
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Zwecke der Datenverarbeitung durch die verantwortliche Stelle und
          Dritte
        </h2>
        <p>
          Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
          Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
          persönlichen Daten an Dritte zu anderen als den genannten Zwecken
          findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte
          weiter, wenn:
        </p>
        <ul role="list" className="marker">
          <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
          <li>
            die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen
            erforderlich ist,
          </li>
          <li>
            die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich ist,
          </li>
          <li>
            die Verarbeitung zur Wahrung berechtigter Interessen erforderlich
            ist
          </li>
          <li>
            und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
            schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
          </li>
        </ul>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Erfassung allgemeiner Informationen beim Besuch unserer Website
        </h2>
        <p>
          Wenn Sie auf unsere Website zugreifen, werden automatisch mittels
          eines Cookies Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers und ähnliches. Hierbei handelt es sich
          ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre
          Person zulassen.
        </p>
        <p>
          Diese Informationen sind technisch notwendig, um von Ihnen
          angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei
          Nutzung des Internets zwingend an. Sie werden insbesondere zu
          folgenden Zwecken verarbeitet:
        </p>
        <ul role="list" className="marker">
          <li>
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
          </li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>
            Auswertung der Systemsicherheit und -stabilität sowie zu weiteren
            administrativen Zwecken.
          </li>
        </ul>
        <p>
          Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem
          berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung.
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und
          ggf. Auftragsverarbeiter.
        </p>
        <p>
          Anonyme Informationen dieser Art werden von uns ggfs. statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Hosting-Dienstleistungen durch einen Drittanbieter
        </h2>
        <p>
          Im Rahmen einer Verarbeitung in unserem Auftrag erbringt mit Netlify
          ein Drittanbieter für uns die Dienste zum Hosting und zur Darstellung
          der Webseite. Dies dient der Wahrung unserer im Rahmen einer
          Interessensabwägung überwiegenden berechtigten Interessen an einer
          korrekten Darstellung unseres Angebots.
        </p>
        <p>
          Alle Daten, die im Rahmen der Nutzung dieser Webseite oder in dafür
          vorgesehenen Formularen wie folgend beschrieben erhoben werden, werden
          auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern
          findet nur in dem hier erläuterten Rahmen statt.
        </p>
        <p>
          Mehr Informationen zu den Datenschutzbemühungen unseres
          Hostinganbieters finden Sie unter{' '}
          <a
            href="https://www.netlify.com/gdpr-ccpa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Netlifys Datenschutzinformationen
          </a>
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">SSL-Verschlüsselung</h2>
        <p>
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Kontaktformular</h2>
        <p>
          Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular
          mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme
          Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen
          E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und
          der anschließenden Beantwortung derselben. Die Angabe weiterer Daten
          ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der
          Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
          gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
          personenbezogene Daten automatisch gelöscht.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Verwendung von Google Analytics</h2>
        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. (folgend: Google). Google Analytics verwendet sog.
          „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen.
          Die durch das Cookie erzeugten Informationen über Ihre Benutzung
          dieser Webseite werden in der Regel an einen Server von Google in den
          USA übertragen und dort gespeichert.
        </p>
        <p>
          Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten,
          wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
          der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
          über den Europäischen Wirtschaftsraum zuvor gekürzt.
        </p>
        <p>
          Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
          Google in den USA übertragen und dort gekürzt. Im Auftrag des
          Betreibers dieser Website wird Google diese Informationen benutzen, um
          Ihre Nutzung der Webseite auszuwerten, um Reports über die
          Webseitenaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Webseitenbetreiber zu erbringen.
        </p>
        <p>
          Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
          IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>
          Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung
          der Website und in der Zusammenstellung von Reports über Aktivitäten
          auf der Website. Auf Grundlage der Nutzung der Website und des
          Internets sollen dann weitere verbundene Dienstleistungen erbracht
          werden. Die Verarbeitung beruht auf dem berechtigten Interesse des
          Webseitenbetreibers.
        </p>
        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können.
        </p>
        <p>
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren: Browser Add On zur
          Deaktivierung von Google Analytics.
        </p>
        <p>
          Zusätzlich oder als Alternative zum Browser-Add-On können Sie das
          Tracking durch Google Analytics auf unseren Seiten unterbinden, indem
          Sie diesen Link anklicken. Dabei wird ein Opt-Out-Cookie auf Ihrem
          Gerät installiert. Damit wird die Erfassung durch Google Analytics für
          diese Website und für diesen Browser zukünftig verhindert, so lange
          das Cookie in Ihrem Browser installiert bleibt.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Verwendung von Scriptbibliotheken (Google Webfonts)
        </h2>
        <p>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und
          Schriftbibliotheken wie z.B.{' '}
          <a
            href="https://www.google.com/webfonts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Webfonts
          </a>
          . Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache
          Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht
          unterstützt oder den Zugriff unterbindet, werden Inhalte in einer
          Standardschrift angezeigt.
        </p>
        <p>
          Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
          automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei
          ist es theoretisch möglich - aktuell allerdings auch unklar ob und
          ggf. zu welchen Zwecken - dass Betreiber entsprechender Bibliotheken
          Daten erheben. Mehr Informationen zur{' '}
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzrichtlinie des Bibliothekbetreibers Google
          </a>
          .
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">Social Plugins</h2>
        <p>
          Auf unseren Webseiten werden Social Plugins der unten aufgeführten
          Anbieter eingesetzt. Die Plugins können Sie daran erkennen, dass sie
          mit dem entsprechenden Logo gekennzeichnet sind.
        </p>
        <p>
          Über diese Plugins werden unter Umständen Informationen, zu denen auch
          personenbezogene Daten gehören können, an den Dienstebetreiber
          gesendet und ggf. von diesem genutzt. Wir verhindern die unbewusste
          und ungewollte Erfassung und Übertragung von Daten an den
          Diensteanbieter durch eine 2-Klick-Lösung. Um ein gewünschtes Social
          Plugin zu aktivieren, muss dieses erst durch Klick auf den
          entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung
          des Plugins wird auch die Erfassung von Informationen und deren
          Übertragung an den Diensteanbieter ausgelöst. Wir erfassen selbst
          keine personenbezogenen Daten mittels der Social Plugins oder über
          deren Nutzung.
        </p>
        <p>
          Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin
          erfasst und wie diese durch den Anbieter verwendet werden. Derzeit
          muss davon ausgegangen werden, dass eine direkte Verbindung zu den
          Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse
          und gerätebezogene Informationen erfasst und genutzt werden. Ebenfalls
          besteht die Möglichkeit, dass die Diensteanbieter versuchen, Cookies
          auf dem verwendeten Rechner zu speichern. Welche konkreten Daten
          hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den
          Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls
          Sie zeitgleich bei Facebook angemeldet sind, kann Facebook Sie als
          Besucher einer bestimmten Seite identifizieren.
        </p>

        <h3 className="heading--tertiary">Google AdWords</h3>
        <p>
          Unsere Webseite nutzt das Google Conversion-Tracking. Sind Sie über
          eine von Google geschaltete Anzeige auf unsere Webseite gelangt, wird
          von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie
          für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von
          Google geschaltete Anzeige klickt. Diese Cookies verlieren nach 30
          Tagen ihre Gültigkeit und dienen nicht der persönlichen
          Identifizierung. Besucht der Nutzer bestimmte Seiten unserer Website
          und das Cookie ist noch nicht abgelaufen, können wir und Google
          erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
          Seite weitergeleitet wurde.
        </p>
        <p>
          Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können
          somit nicht über die Websites von AdWords-Kunden nachverfolgt werden.
          Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen.
        </p>
        <p>
          Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür
          erforderliche Setzen eines Cookies ablehnen - etwa per
          Browser-Einstellung, die das automatische Setzen von Cookies generell
          deaktiviert oder Ihren Browser so einstellen, dass Cookies von der
          Domain „googleleadservices.com“ blockiert werden.
        </p>
        <p>
          Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen,
          solange Sie keine Aufzeichnung von Messdaten wünschen. Haben Sie alle
          Ihre Cookies im Browser gelöscht, müssen Sie das jeweilige Opt-out
          Cookie erneut setzen.
        </p>
        <h3 className="heading--tertiary">Einsatz von Google Remarketing</h3>
        <p>
          Diese Webseite verwendet die Remarketing-Funktion der Google Inc. Die
          Funktion dient dazu, Webseitenbesuchern innerhalb des
          Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu
          präsentieren. Im Browser des Webseitenbesuchers wird ein sog. „Cookie“
          gespeichert, der es ermöglicht, den Besucher wiederzuerkennen, wenn
          dieser Webseiten aufruft, die dem Werbenetzwerk von Google angehören.
          Auf diesen Seiten können dem Besucher Werbeanzeigen präsentiert
          werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf
          Webseiten aufgerufen hat, die die Remarketing Funktion von Google
          verwenden.
        </p>
        <p>
          Nach eigenen Angaben erhebt Google bei diesem Vorgang keine
          personenbezogenen Daten. Sollten Sie die Funktion Remarketing von
          Google dennoch nicht wünschen, können Sie diese grundsätzlich
          deaktivieren, indem Sie die entsprechenden Anpassungen über{' '}
          <a
            href="http://www.google.com/settings/ads"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Ad Einstellungen
          </a>{' '}
          vornehmen. Alternativ können Sie den Einsatz von Cookies für
          interessenbezogene Werbung über die Werbenetzwerkinitiative
          deaktivieren, indem Sie den{' '}
          <a
            href="http://www.networkadvertising.org/managing/opt_out.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Opt-Out Anweisungen
          </a>{' '}
          folgen.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Änderung unserer Datenschutzbestimmungen
        </h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
      </div>
      <div className="legal__block grid">
        <h2 className="heading--secondary">
          Fragen an den Datenschutzbeauftragten
        </h2>
        <p>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:{' '}
          <Email email="hello@contective.digital" />
        </p>
      </div>

      <span>Stand: 01.06.2022</span>
    </section>
  )
}
