import * as React from 'react'
import Tilt from 'react-parallax-tilt'

// COMPONENTS
import IconContent from '../../svgs/icon_content.inline.svg'
import IconCode from '../../svgs/icon_code.inline.svg'
import IconPerformance from '../../svgs/icon_performance.inline.svg'
import IconAB from '../../svgs/icon_AB.inline.svg'
import { MediaType } from './MediaType'

// HELPERS
import addPropertyToObject from '../../helpers/addPropertyToObject'

export const ServicesCards = ({ services }) => {
  const icons = [IconPerformance, IconContent, IconAB, IconCode]
  addPropertyToObject(services, 'icon', icons)

  const checkEvenOdd = (index, number) => {
    return index % 2 === 0 ? number : number * -1
  }

  return (
    <ul className="grid" role="list">
      {services.map((service, index) => (
        <li key={index}>
          <article className="services__card grid">
            <div className="content flex-col">
              {service.icon()}
              <h3 className="heading--primary">{service.serviceName}</h3>
              <p className="copy">{service.desc}</p>
            </div>
            <Tilt
              tiltAngleXInitial={service['Tilt Settings'].tiltX}
              tiltAngleYInitial={checkEvenOdd(
                index,
                service['Tilt Settings'].tiltY
              )}
            >
              <div className="illustration">
                <MediaType media={service.media} index={index} />
              </div>
            </Tilt>
          </article>
        </li>
      ))}
    </ul>
  )
}
