import React from 'react'

// COMPONENTS
import { BgDots } from '../components/BgDots/BgDots'
import { Seo } from '../components/SEO/SEO'

// // STYLES
import './scss/main.scss'

export default function RootLayout(props) {
  return (
    <React.Fragment>
      <Seo />
      <BgDots />
      <div className="noise" />
      <div>{props.children}</div>
    </React.Fragment>
  )
}
