import { Builder, withChildren } from '@builder.io/react'
import { Services } from './Services'

const ServicesWithChildren = withChildren(Services)

Builder.registerComponent(ServicesWithChildren, {
  name: 'Services',

  inputs: [
    { name: 'title', type: 'string' },
    { name: 'subtitle', type: 'longText' },
    {
      name: 'services',
      type: 'list',
      defaultValue: [{ serviceName: '' }],
      subFields: [
        {
          name: 'serviceName',
          type: 'string',
          defaultValue: '',
          friendlyName: 'Service Name'
        },
        {
          name: 'desc',
          type: 'longText',
          friendlyName: 'Description'
        },
        {
          name: 'media',
          type: 'object',
          defaultValue: {},
          subFields: [
            {
              name: 'mediaType',
              type: 'string',
              defaultValue: 'img',
              enum: ['img', 'video'],
              required: true
            },
            {
              name: 'mediaFile',
              type: 'file',
              allowedFileTypes: ['jpg', 'jpeg', 'png', 'svg', 'mp4'],
              helperText:
                'Empfohlene Auflösung für Bilder: Weite 1000px mal Höhe 1400px bis 1600px. Video Dateien müssen als mp4 hochgeladen werden.'
            },
            {
              name: 'playbackRate',
              type: 'number',
              defaultValue: 1
            },
            {
              name: 'imageAltText',
              type: 'longText',
              helperText:
                'Alt Text sollte keine bloße Aneinanderreihung von Keywords sein, sondern eine zusammenhängende Abfolge von Sätzen, die das Bild im Kontext der Website erklären. Für das Formulieren ist es hilfreich, sich in die Position eines Nichtsehenden zu versetzen.'
            }
          ]
        },
        {
          name: 'tiltSettings',
          type: 'object',
          friendlyName: 'Tilt Settings',
          defaultValue: {
            tiltX: 4
          },
          subFields: [
            {
              name: 'tiltX',
              type: 'number',
              defaultValue: 4,
              required: true
            },
            {
              name: 'tiltY',
              type: 'number',
              defaultValue: 8,
              required: true
            }
          ]
        }
      ]
    },
    {
      name: 'banner',
      type: 'list',
      defaultValue: [{ text: '' }],
      subFields: [{ name: 'text', type: 'string' }],
      helperText: '3 bis 4 Wörter sind optimal für den Ablauf des Banners'
    }
  ]
})
