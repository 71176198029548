import * as React from 'react'
import { Link } from 'gatsby'
import { InView } from 'react-intersection-observer'

// HELPERS
import slugify from '../../helpers/slugify'

export const VerticalSlider = (props) => {
  const { content } = props
  const [activeCategory, setActiveCategory] = React.useState(0)

  React.useEffect(() => {
    updateCustomPropertyValue('--vertical-slider-step', activeCategory)
  }, [activeCategory])

  function transformToLink(str) {
    let link = `#${slugify(str)}`
    return link
  }

  function updateActiveCategory(index) {
    setActiveCategory(index)
  }

  function updateCustomPropertyValue(variable, value) {
    document.documentElement.style.setProperty(variable, `${value}`)
  }

  return (
    <section class="vertical-slider grid">
      <aside>
        <ul role="list" className="grid">
          {content.map((item, index) => (
            <li>
              <Link
                to={transformToLink(item.category)}
                className={
                  activeCategory === index
                    ? 'vertical-slider__link vertical-slider__link-active'
                    : 'vertical-slider__link'
                }
                onClick={() => updateActiveCategory(index)}
              >
                {item.category}
              </Link>
            </li>
          ))}
        </ul>
      </aside>
      <div class="content__detail">
        <ul role="list" className="content__container grid">
          {content.map((item, index) => (
            <li key={index}>
              <article id={slugify(item.category)} className="grid">
                <InView
                  onChange={(inView, entry) =>
                    inView ? updateActiveCategory(index) : null
                  }
                />
                <h2 className="heading--secondary">{item.headline}</h2>
                {item.copy.map((item) => (
                  <p className="copy">{item.text}</p>
                ))}
              </article>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
