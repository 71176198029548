import * as React from 'react'

// HELPERS
import checkIfArray from '../../helpers/checkIfArray'
import cls from '../../helpers/classNamesRendering'

const defaultSize = 'h2'

const styles = {
  h1Page: 'heading--page',
  h1: 'heading--primary',
  h2: 'heading--secondary'
}

const direction = {
  left: 'text--left',
  center: 'text--center',
  right: 'text--right'
}

export const Title = ({
  as,
  className,
  pageHeading = false,
  subheading,
  textPos,
  ...rest
}) => {
  const Tag = as ?? defaultSize
  const style = pageHeading ? 'h1Page' : Tag
  const checkedSubheading = checkIfArray(subheading)

  return (
    <div className={cls(direction[textPos ?? null], 'title__wrapper grid')}>
      <Tag
        className={cls(styles[style], 'heading--base', className)}
        {...rest}
      />
      {checkedSubheading
        ? checkedSubheading.map((p, index) => (
            <p key={index} className="copy">
              {p.text ?? p}
            </p>
          ))
        : null}
    </div>
  )
}
