import * as React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { BuilderComponent, builder } from '@builder.io/react'
import '../builder-settings'

builder.canTrack = false
builder.init(process.env.BUILDER_API_KEY)

const query = graphql`
  query {
    allBuilderModels {
      announcement(limit: 1, options: { cachebust: true }) {
        content
      }
      header(limit: 1, options: { cachebust: true }) {
        content
      }
      footer(limit: 1, options: { cachebust: true }) {
        content
      }
      theme(limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`

function PageLayout({ children }) {
  return (
    <StaticQuery query={query}>
      {(data) => {
        const models = data.allBuilderModels
        const announcement = models.announcement[0].content
        const header = models.header[0].content
        const footer = models.footer[0].content
        const theme = models.theme[0].content

        function checkIfObjectIsEmpty(obj) {
          for (let i in obj) return false
          return true
        }

        return (
          <div>
            <div>
              {!checkIfObjectIsEmpty(announcement.data) && (
                <BuilderComponent
                  renderLink={Link}
                  name="announcement"
                  content={announcement}
                />
              )}
            </div>
            <BuilderComponent renderLink={Link} name="theme" content={theme} />
            <Link to="#main" className="sr__skip-link">
              Zum Hauptinhalt
            </Link>
            <div>
              <BuilderComponent
                renderLink={Link}
                name="header"
                content={header}
              />
            </div>

            <main id="main">{children}</main>

            <div>
              <BuilderComponent
                renderLink={Link}
                name="footer"
                content={footer}
              />
            </div>
          </div>
        )
      }}
    </StaticQuery>
  )
}

export default PageLayout
