import * as React from 'react'
import { useInView } from 'react-intersection-observer'

// COMPONENTS
import { HeadingSection } from '../HeadingSection/HeadingSection'
import IconFair from '../../svgs/icon_pricing-fair.inline.svg'
import IconTransparent from '../../svgs/icon_transparent-plan.inline.svg'

export const Pricing = ({ title, explanations, children }) => {
  const { ref, inView } = useInView()

  return (
    <section className="pricing">
      <HeadingSection title={title} />
      <div className="pricing__composition grid">
        <div className="pricing__visual">
          <IconFair />
        </div>
        <div
          ref={ref}
          className={
            inView
              ? 'pricing__visual wrapper js__active'
              : 'pricing__visual wrapper'
          }
        >
          <IconTransparent />
        </div>
        {explanations.map((item, index) => (
          <div className="pricing__content wrapper" key={index}>
            <h2 className="heading--secondary">{item.heading}</h2>
            <p className="copy">{item.explanation}</p>
          </div>
        ))}
        {children}
      </div>
    </section>
  )
}
