import * as React from 'react'

// COMPONENTS
import { Counter } from './Counter'

export const Stat = ({ data, duration }) => {
  const { icon, detail, stat, number, suffix } = data
  const { defaultProps } = icon

  return (
    <>
      {icon({ ...defaultProps, className: 'stats__card-icon' })}
      <p className="stats__card-stat copy">
        <Counter number={number} duration={duration} suffix={suffix} /> <br />
        {stat}
      </p>
      <p className="stats__card-detail copy">{detail}</p>
    </>
  )
}
