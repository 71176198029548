import * as React from 'react'

import capitalizeFirstLetter from '../../helpers/capitalizeString'
import cls from '../../helpers/classNamesRendering'

export const Tab = ({ tab, selectedTab, onClick }) => {
  return (
    <button
      className={cls(
        `${selectedTab === tab.id ? tab.class + ' js__active' : tab.class}`
      )}
      onClick={onClick}
    >
      {capitalizeFirstLetter(tab.name)}
    </button>
  )
}
