import * as React from 'react'

// COMPONENTS
import { InputWithSubmitBtn } from '../Input/Input'

export const Newsletter = ({ newsletter }) => {
  const { newsletterTitle, inputPlaceholder, newsletterCopy } = newsletter
  const [email, setEmail] = React.useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (email) {
      const encodedEmail = encodeURIComponent(email)

      const subscribe = await (
        await fetch(`/.netlify/functions/newsletter?email=${encodedEmail}`)
      ).json()
    }
  }

  return (
    <article className="newsletter">
      <form
        name="Newsletter Form"
        className="form flex-col"
        onSubmit={handleSubmit}
        data-netlify="true"
      >
        <input type="hidden" name="subject" value="Newsletter" />

        <InputWithSubmitBtn
          htmlFor={'mail'}
          copy={newsletterCopy}
          type={'email'}
          label={newsletterTitle}
          ariaLabel={'Anmeldung abschicken'}
          placeholder={inputPlaceholder}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <input type="hidden" name="form-name" value="Newsletter Form" />
      </form>
    </article>
  )
}
