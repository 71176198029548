import { Builder } from '@builder.io/react'
import { Invitation } from './Invitation'

Builder.registerComponent(Invitation, {
  name: 'Invitation',
  noWrap: true,
  inputs: [
    {
      name: 'headline1',
      type: 'string',
      helperText:
        'Headline 1 und Underlined müssen identisch sein, damit der Underline-Effekt gerendert wird. Satzzeichen wie ?, ! oder . müssen dabei nicht berücksichtigt werden.'
    },

    { name: 'underlined', type: 'string' },
    { name: 'headline2', type: 'string' },
    { name: 'ctaText', type: 'string' },
    { name: 'ctaLink', type: 'string' },
    {
      name: 'benefits',
      type: 'list',
      defaultValue: [{ benefit: '' }],
      subFields: [
        {
          name: 'benefit',
          type: 'string'
        }
      ]
    }
  ]
})
