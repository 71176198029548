import { Builder } from '@builder.io/react'
import { Funnel } from './Funnel'

Builder.registerComponent(Funnel, {
  name: 'Funnel',
  inputs: [
    { name: 'title', type: 'string' },
    { name: 'subheadingCopy', type: 'longText' },
    {
      name: 'tabs',
      type: 'list',
      defaultValue: [{ id: '' }],
      subFields: [
        { name: 'id', type: 'string' },
        { name: 'name', type: 'string' },
        {
          name: 'class',
          type: 'string'
        },
        {
          name: 'nameLong',
          type: 'string'
        },
        {
          name: 'content',
          type: 'longText'
        }
      ]
    }
  ]
})
