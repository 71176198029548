import { Builder } from '@builder.io/react'
import { Theme } from './Theme'

Builder.registerComponent(Theme, {
  name: 'Theme',
  inputs: [
    {
      name: 'colors',
      type: 'object',
      defaultValue: { primary: 'rgba(0, 17, 171, 1)' },
      subFields: [
        {
          name: 'primary',
          type: 'color',
          defaultValue: 'rgba(0, 17, 171, 1)'
        },
        {
          name: 'secondary',
          type: 'color',
          defaultValue: 'rgba(187, 0, 135, 1)'
        },
        {
          name: 'accent',
          type: 'color',
          defaultValue: 'rgba(3, 227, 175, 1)'
        },
        {
          name: 'btn-primary',
          type: 'color',
          defaultValue: 'rgba(3, 227, 175, 1)'
        },
        {
          name: 'btn-funnel',
          type: 'color',
          defaultValue: 'rgba(74, 144, 226, 1)'
        }
      ]
    }
  ]
})
