import * as React from 'react'
import { Link } from 'gatsby'
import { Email } from 'react-obfuscate-email'

// COMPONENTS
import IconSocialFb from '../../svgs/social_fb.inline.svg'
import IconSocialLinkedIn from '../../svgs/social_linkedin.inline.svg'
// import IconSocialTikTok from '../../svgs/social_tiktok.inline.svg'
import IconMail from '../../svgs/contact_mail.inline.svg'
import IconPhone from '../../svgs/contact_phone.inline.svg'

// HELPERS
import phoneNumberReadableFormat from '../../helpers/phoneNumberReadableFormat'

export const LinksCollection = (props) => {
  const { links } = props
  return (
    <ul className="links__collection" role="list">
      {links.map((link) => (
        <li key={link.id}>
          <Link to={link.url} activeClassName="link__active">
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const LinksSocial = (props) => {
  const { links } = props
  return (
    <ul className="links__social flex-row" role="list">
      <li>
        <a
          href={links[0].url}
          className="link__social"
          title="Facebook"
          aria-label="Contective auf Facebook"
        >
          <IconSocialFb />
        </a>
      </li>
      {/* <li>
        <a href={links[1].url} className="link__social" title="TikTok">
          <IconSocialTikTok />
        </a>
      </li> */}
      <li>
        <a
          href={links[2].url}
          className="link__social"
          title="LinkedIn"
          aria-label="Contective auf LinkedIn"
        >
          <IconSocialLinkedIn />
        </a>
      </li>
    </ul>
  )
}

export const LinksContact = ({ links }) => {
  const { tel, email } = links

  return (
    <ul className="links__contact" role="list">
      <li key={tel.id}>
        <a
          href={`${tel.type}${tel.contact}`}
          className="link__contact flex-row"
        >
          <IconPhone className="link__contact-phone" />
          <span>{phoneNumberReadableFormat(tel.contact)}</span>
        </a>
      </li>
      <li key={email.id} className="link__contact flex-row">
        <IconMail className="link__contact-mail" />
        <Email email={email.contact}></Email>
      </li>
    </ul>
  )
}
