import * as React from 'react'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'

export const Counter = ({ number, ...props }) => {
  const [ref, inView] = useInView()
  const { duration, suffix } = props

  return (
    <span ref={ref}>
      <CountUp
        start={0}
        end={inView ? number : 0}
        separator={' '}
        useEasing={true}
        duration={duration}
        suffix={suffix ?? ''}
      />
    </span>
  )
}
