import * as React from 'react'

// COMPONENTS
import BgDots from '../../svgs/bg_dots.inline.svg'
import { BtnPrimary } from '../Buttons/Buttons'
import IconCheck from '../../svgs/icon_check.inline.svg'
import { Underline } from '../Underline/Underline'

export const Invitation = (props) => {
  const { headline1, headline2, underlined, ctaText, ctaLink, benefits } = props
  delete props.attributes['className']

  return (
    <section className="invitation" {...props.attributes}>
      <div className="bg--dots">
        <BgDots />
      </div>
      <div className="wrapper flex-col">
        <div className="invitation__content flex-col">
          <h2 className="invitation__heading heading--primary">
            <Underline headline={headline1} underlined={underlined} />
            <br />
            {headline2}
          </h2>

          <BtnPrimary ctaText={ctaText} ctaLink={ctaLink} />
        </div>
        <div className="invitation__benefits">
          <ul className="flex-col" role="list">
            {benefits.map((item, index) => (
              <li key={index} className="flex-row">
                <IconCheck />
                {item.benefit}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
