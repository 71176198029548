import * as React from 'react'
import { Image } from '@builder.io/react'

export const LogoBanner = ({ copy, logos, title }) => {
  if (logos === undefined || logos.length === 0) return null

  return (
    <article className="logobanner">
      <div className="logobanner__container">
        <div className="logobanner__text">
          <h2 className="heading--primary logobanner__heading">{title}</h2>
          <p className="copy">{copy}</p>
        </div>

        <ul className="logobanner__list" role="list">
          {logos.map((logo, index) => (
            <li
              key={index}
              className={`${
                logos.length % 2 !== 0 && index === logos.length - 1
                  ? 'logobanner__list-item--last'
                  : 'logobanner__list-item'
              }`}
            >
              <Image src={logo.image} alt={logo.client} />
            </li>
          ))}
        </ul>
      </div>
    </article>
  )
}
