import * as React from 'react'

export const HeadingSection = ({ title, subheading }) => {
  if (subheading) {
    return (
      <div className="heading--section flex-col">
        <h2 className="heading--primary">{title}</h2>

        <p className="copy">{subheading}</p>
      </div>
    )
  } else {
    return (
      <div className="heading--section flex-col">
        <h2 className="heading--primary">{title}</h2>
      </div>
    )
  }
}
