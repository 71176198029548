import { Builder } from '@builder.io/react'
import { Careers } from './Careers'
import { JobLink } from './JobLink'

Builder.registerComponent(Careers, {
  name: 'Careers',
  inputs: [
    {
      name: 'headline',
      type: 'string'
    },
    {
      name: 'intro',
      type: 'list',
      defaultValue: [{ text: '' }],
      subFields: [
        {
          name: 'text',
          type: 'longText'
        }
      ]
    },
    { name: 'jobRequirementsTitle', type: 'string' },
    {
      name: 'jobs',
      type: 'list',
      defaultValue: [{ jobTitle: '' }],
      subFields: [
        {
          name: 'jobTitle',
          type: 'string'
        },
        { name: 'jobLocation', type: 'string', defaultValue: 'remote' },
        { name: 'jobGender', type: 'string', defaultValue: '(w/m/d)' },
        { name: 'jobHours', type: 'string', defaultValue: '' },
        {
          name: 'jobDesc',
          type: 'longText'
        },
        {
          name: 'jobRequirements',
          type: 'list',
          defaultValue: [{ requirement: '' }],
          subFields: [{ name: 'requirement', type: 'string' }]
        }
      ]
    }
  ]
})

Builder.registerComponent(JobLink, {
  name: 'Job Link',
  noWrap: true,
  inputs: [
    {
      name: 'headline',
      type: 'string'
    },
    {
      name: 'text',
      type: 'longText'
    },
    {
      name: 'ctatext',
      type: 'string'
    },
    {
      name: 'ctalink',
      type: 'string'
    }
  ]
})
