import * as React from 'react'

export const JobBoard = (props) => {
  const { jobs } = props

  return (
    <div className="careers__jobboard grid">
      {<h2 className="heading--primary">Offene Stellen ({jobs.length})</h2>}
      {props.children}
    </div>
  )
}
