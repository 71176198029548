import * as React from 'react'

const UnderlineSVG = () => {
  return (
    <svg
      className="underline"
      viewBox="0 0 409 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.632812 1.57606C4.55581 1.25606 6.39081 0.522057 12.7428 0.684057C46.8328 1.54806 79.9968 1.83706 117.22 2.46206C173.324 3.41206 231.179 3.51606 290.784 2.69506C315.372 2.38806 346.316 2.75206 375.605 2.65106C383.619 2.68006 400.763 3.46106 405.442 4.17806C410.247 4.89206 408.852 6.30806 403.267 6.75806C386.388 8.13656 369.474 9.05024 352.545 9.49806C312.476 10.5545 272.394 11.0179 232.312 10.8881C192.016 10.7461 151.525 9.96406 110.891 8.60506C78.7638 7.52206 44.7538 5.64706 11.5938 3.55106L0.632812 1.57606Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Underline = ({ headline, underlined }) => {
  let headlineArr = headline.split(' ')

  function removePunctuation(str) {
    return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
  }

  return (
    <span>
      {headlineArr.map((str, index) =>
        removePunctuation(str) === underlined ? (
          <span className="underlined" key={index}>
            {str}
            <UnderlineSVG />
          </span>
        ) : (
          <span key={index}>{str}</span>
        )
      )}
    </span>
  )
}
