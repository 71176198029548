import * as React from 'react'

export const JobPosting = ({ job, jobRequirementsTitle }) => {
  const { jobDesc, jobRequirements, jobLocation, jobGender, jobHours } = job
  return (
    <article className="job">
      <div className="grid">
        <div className="job__description">
          <div className="job__details flex-row">
            <span>{jobGender}</span>
            <span>{jobLocation}</span>
            <span>{jobHours}</span>
          </div>
          <p className="copy">{jobDesc}</p>
        </div>

        <div className="job__requirements">
          <h4 className="job__requirements-title heading--tertiary">
            {jobRequirementsTitle}
          </h4>
          <ul role="list" className="marker grid">
            {jobRequirements.map((item) => (
              <li className="copy">{item.requirement}</li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  )
}
