import * as React from 'react'
import { Link } from 'gatsby'

// COMPONENTS
import { BtnSecondary } from '../Buttons/Buttons'

export const JobLink = (props) => {
  const { headline, text, ctatext, ctalink } = props
  return (
    <div
      variant={props.variant}
      {...props.attributes}
      className={'job__link grid'}
    >
      <h2 className="heading--secondary">{headline}</h2>
      <p className="copy">{text}</p>
      <div className="container">
        <Link to={ctalink}>
          <BtnSecondary ctaText={ctatext} />
        </Link>
      </div>
    </div>
  )
}
