import * as React from 'react'

// COMPONENTS
import { FAQ } from '../FAQ/FAQ'
import { JobBoard } from './JobBoard'
import { JobForm } from './JobForm'
import { Title } from '../HeadingSection/Title'

export const Careers = ({ headline, intro, jobRequirementsTitle, jobs }) => {
  const jobsData = { jobs, jobRequirementsTitle }
  return (
    <section className="careers">
      <div className="wrapper">
        <Title
          as={'h1'}
          className={'careers__heading'}
          pageHeading={true}
          subheading={intro}
        >
          {headline}
        </Title>
        <JobBoard jobs={jobs}>
          <FAQ data={jobsData} styles={'careers__jobpost'} />
        </JobBoard>

        <JobForm jobs={jobs} />
      </div>
    </section>
  )
}
