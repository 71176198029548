import { Builder } from '@builder.io/react'
import { BtnPrimary, BtnSecondary } from './Buttons'

Builder.registerComponent(BtnPrimary, {
  name: 'Button Primary',
  inputs: [
    { name: 'ctaText', type: 'string', defaultValue: 'I am a primary button!' },
    { name: 'ctaLink', type: 'string' }
  ]
})

Builder.registerComponent(BtnSecondary, {
  name: 'Button Secondary',
  inputs: [
    {
      name: 'ctaText',
      type: 'string',
      defaultValue: 'I am a secondary button!'
    }
  ]
})
