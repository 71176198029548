import * as React from 'react'

// Components
import IconCRO from '../../svgs/icon_cro.inline.svg'
import IconIncrease from '../../svgs/icon_increase.inline.svg'
import IconAds from '../../svgs/icon_ads.inline.svg'
import { Stat } from './Stat'

// HELPERS
import addPropertyToObject from '../../helpers/addPropertyToObject'

export const Stats = (props) => {
  const icons = [IconCRO, IconIncrease, IconAds]
  const { stats, duration } = props

  const statsWithIcons = addPropertyToObject(stats, 'icon', icons)

  return (
    <section className="stats">
      <div className="wrapper">
        <ul className="grid" role="list">
          {statsWithIcons.map((stat, index) => (
            <li key={index} className="stats__card grid">
              <Stat data={stat} duration={duration} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
