import * as React from 'react'

// HELPERS
import cls from '../../helpers/classNamesRendering'

export const Tabs = ({ classes, children }) => {
  return (
    <ul role="list" className={cls(`${classes} flex-row`)}>
      {children}
    </ul>
  )
}
