import * as React from 'react'

// COMPONENTS
import { Dev } from './Dev'
import {
  LinksCollection,
  LinksSocial,
  LinksContact
} from '../LinksCollection/LinksCollection'
import { Logo } from '../Logo/Logo'
import { Newsletter } from '../Forms/Newsletter'
import { Slogan } from '../Slogan/Slogan'

// DATA
import contactData from '../../global/contactData.js'

export const Footer = (props) => {
  const { linkContainer1, linkContainer2, contactCopy, newsletter } = props

  const links = [
    { id: '3', name: 'Vision', url: '/vision' },
    { id: '2', name: 'Über uns', url: '/about' },
    { id: '1', name: 'Jobs', url: '/jobs' },
    { id: '4', name: 'Kontakt', url: '/contact' }
  ]

  const contacts = [
    { id: '1', type: 'tel:', contact: '+491784602412' },
    { id: '2', type: 'mailto:', contact: 'hello@contective.digital' }
  ]

  const socials = [
    {
      id: '1',
      name: 'facebook',
      url: 'https://www.facebook.com/contective-digital'
    },
    {
      id: '2',
      name: 'tiktok',
      url: 'https://www.tiktok.com/contective-digital'
    },
    {
      id: '3',
      name: 'linkedIn',
      url: 'https://www.linkedin.com/company/contective'
    }
  ]

  const legalLinks = [
    { id: '5', name: 'Impressum', url: '/impressum' },
    { id: '6', name: 'Datenschutz', url: '/datenschutz' }
  ]

  function getYear() {
    return `${new Date().getFullYear()}`
  }

  return (
    <footer className="footer">
      <div className="footer__newsletter wrapper"></div>
      <div className="footer__main wrapper grid">
        <div className="footer__logo flex-col">
          <Logo />
          <Slogan>
            <p>
              Connecting Content with <br /> Technology and Creativity
            </p>
          </Slogan>
          <div className="link__container">
            <LinksSocial links={socials} />
          </div>
        </div>
        <div className="link__container">
          <h2>{linkContainer1}</h2>
          <LinksCollection links={links} />
        </div>
        <div className="link__container">
          <h2>{linkContainer2}</h2>
          {contactCopy &&
            contactCopy.map((item, index) => <p key={index}>{item.text}</p>)}
          <LinksContact links={contactData} />
        </div>
        <Newsletter newsletter={newsletter} />
      </div>
      <div className="footer__sub">
        <div className="wrapper flex-row">
          <div className="footer__legal flex-row">
            <p className="copyright">&copy; {getYear()} contective.digital</p>
            <LinksCollection links={legalLinks} />
          </div>

          <Dev />
        </div>
      </div>
    </footer>
  )
}
