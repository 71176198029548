import { Builder } from '@builder.io/react'
import { Form } from './Form'

Builder.registerComponent(Form, {
  name: 'Contact Form v2',
  inputs: [
    {
      name: 'contactInfo',
      type: 'object',
      defaultValue: { title: 'Kontakt' },
      subFields: [
        {
          name: 'title',
          type: 'string'
        },
        {
          name: 'copy',
          type: 'list',
          defaultValue: [{ text: '' }],
          subFields: [
            {
              name: 'text',
              type: 'string'
            }
          ]
        }
      ]
    },
    {
      name: 'contactFormData',
      type: 'object',
      defaultValue: { title: 'Schreib uns' },
      subFields: [
        {
          name: 'title',
          type: 'string'
        },
        {
          name: 'ctaText',
          type: 'string'
        },
        {
          name: 'options',
          type: 'list',
          defaultValue: [
            {
              option: ''
            }
          ],
          subFields: [
            {
              name: 'option',
              type: 'string'
            }
          ]
        }
      ]
    }
  ]
})
