import { Builder } from '@builder.io/react'
import { Footer } from './Footer'

Builder.registerComponent(Footer, {
  name: 'Footer',
  inputs: [
    {
      name: 'linkContainer1',
      type: 'string'
    },
    {
      name: 'linkContainer2',
      type: 'string'
    },
    {
      name: 'contactCopy',
      type: 'list',
      defaultValue: [{ text: '' }],
      subFields: [{ name: 'text', type: 'string' }]
    },
    {
      name: 'newsletter',
      type: 'object',
      defaultValue: {
        newsletterTitle: 'Melde dich für unseren Newsletter an!'
      },
      subFields: [
        {
          name: 'newsletterTitle',
          type: 'string'
        },
        {
          name: 'newsletterCopy',
          type: 'longText'
        },
        {
          name: 'inputPlaceholder',
          type: 'string'
        }
      ]
    },

    {
      name: 'socialMediaLinks',
      type: 'list',
      defaultValue: [{ platform: '' }],
      subFields: [
        {
          name: 'platform',
          type: 'string'
        },
        {
          name: 'url',
          type: 'string',
          regex:
            "^(?:http(s)?://)?[w.-]+(?:.[w.-]+)+[w-._~:/?#[]@!$&'()*+,;=.]+$"
        }
      ]
    }
  ]
})
