const contactData = {
  tel: {
    id: '1',
    type: 'tel:',
    contact: '+491784602412'
  },
  email: {
    id: '2',
    type: 'mailto:',
    contact: 'hello@contective.digital'
  }
}
export default contactData
