const phoneNumberReadableFormat = (str) => {
  let newStr = str.trim()
  if (!str.startsWith('+49')) {
    return str
  }

  const strParts = [
    newStr.slice(0, 3),
    '(0)',
    splitNumberIntoNSegments(str.slice(3))
  ]
    .flat()
    .join(' ')

  newStr = strParts

  return newStr
}

function splitNumberIntoNSegments(str) {
  return str.match(/[\s\S]{1,3}/g) || []
}

export default phoneNumberReadableFormat
