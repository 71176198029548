import * as React from 'react'

export const BurgerMenu = ({ opened, setOpened }) => {
  const title = opened ? 'Menü schließen' : 'Menü öffnen'
  const toggleMenu = () => {
    setOpened((o) => !o)
  }
  return (
    <button
      className={`btn--mobile-menu ${opened ? 'js__active' : null}`}
      onClick={() => toggleMenu()}
      title={title}
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </button>
  )
}
