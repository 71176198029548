import * as React from 'react'

export const Marquee = ({ banner }) => {
  return (
    <div className="marquee">
      <div className="marquee__container">
        {[...Array(2)].map((elem, index) => (
          <div className="marquee__box" key={index}>
            {[...Array(3)].map((elem, index) =>
              banner.map((item) => <span>{item.text}</span>)
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
