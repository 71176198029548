import { Builder } from '@builder.io/react'
import { LogoBanner } from './LogoBanner'

Builder.registerComponent(LogoBanner, {
  name: 'Logo Banner',
  inputs: [
    { name: 'title', type: 'string' },
    { name: 'copy', type: 'longText' },
    {
      name: 'logos',
      type: 'list',
      defaultValue: [{ logo: '' }],
      subFields: [
        {
          name: 'client',
          type: 'string',
          defaultValue: '',
          friendlyName: 'Client'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['png'],
          helperText: 'Maximal 200px hoch',
          friendlyName: 'Image File',
          required: true
        }
      ]
    }
  ]
})
