import React, { useState, useEffect, useRef } from 'react'

// COMPONENTS
import { HeadingSection } from '../HeadingSection/HeadingSection'
import { Tabs } from '../Tabs/Tabs'
import { Tab } from '../Tabs/Tab'
import { FunnelSVG } from './FunnelSVG'

export const Funnel = ({ title, subheadingCopy, tabs }) => {
  const [selectedTab, setTab] = useState(tabs[0].id)
  const [currentIndex, setCurrentIndex] = useState(0)
  const timeoutRef = useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      currentIndex === tabs.length - 1
        ? setCurrentIndex(0)
        : setCurrentIndex((prevIndex) => prevIndex + 1)
      setTab(tabs[currentIndex].id)
    }, 10_000)

    return () => resetTimeout()
  }, [currentIndex])

  return (
    <section className="funnel">
      <HeadingSection title={title} subheading={subheadingCopy} />
      <div className="wrapper grid">
        <Tabs data={tabs} classes="funnel__tabs">
          {tabs.map((tab, index) => (
            <li key={tab.id} className={index <= 1 ? 'flex-row' : null}>
              <Tab
                selectedTab={selectedTab}
                onClick={() => setTab(tab.id)}
                tab={tab}
              />
              {index <= 1 ? <span className="funnel__step-line"></span> : null}
            </li>
          ))}
        </Tabs>
        <div className="funnel__illustration">
          <FunnelSVG selectedTab={selectedTab} />
        </div>
        <div className="funnel__content">
          <div className="funnel__tabs-data grid">
            {tabs.map((tab) => (
              <button
                onClick={() => setTab(tab.id)}
                key={tab.id}
                type="button"
                className={` ${
                  selectedTab === tab.id
                    ? 'funnel__tab-data js__active'
                    : 'funnel__tab-data'
                }`}
              >
                <div className="funnel__copy">
                  <h3 className="funnel__heading heading--tertiary">
                    {tab.nameLong}
                  </h3>
                  <p className="funnel__explanation copy">{tab.content}</p>
                </div>
              </button>
            ))}
            <span className="funnel__bar"></span>
            <span className="funnel__bar"></span>
            <span className="funnel__bar"></span>
          </div>
        </div>
      </div>
    </section>
  )
}
