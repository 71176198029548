import * as React from 'react'

export const Dev = () => {
  return (
    <div className="dev flex-row">
      <p>
        Website gebaut mit {''}
        <a href="https://www.gatsbyjs.com" rel="noreferrer" target="_blank">
          Gatsby.js
        </a>
        {''} und {''}
        <a href="https://www.builder.io" rel="noreferrer" target="_blank">
          builder.io
        </a>
        .{''} Gehostet mit {''}
        <a href="https://www.netlify.com" rel="noreferrer" target="_blank">
          netlify
        </a>
        .
      </p>
    </div>
  )
}
