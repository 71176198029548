import { Builder, withChildren } from '@builder.io/react'
import { Hero } from './Hero'

const HeroWithChildren = withChildren(Hero)

Builder.registerComponent(HeroWithChildren, {
  name: 'Hero',
  inputs: [
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'subtitle',
      type: 'string'
    },
    {
      name: 'ctaHero',
      type: 'string'
    },
    { name: 'ctaHeroLink', type: 'string' },
    { name: 'slogan', type: 'string' },
    {
      name: 'video',
      type: 'file',
      allowedFileTypes: ['mp4'],
      defaultValue: '/assets/videos/test.mp4'
    }
  ]
})
