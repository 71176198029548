import * as React from 'react'
import { Link } from 'gatsby'

// COMPONENTS
import { Avatar } from './Avatar'
import { BtnSecondary } from '../Buttons/Buttons'
import { Marquee } from '../Marquee/Marquee'

export const About = ({ title, subheadingCopy, team, benefits }) => {
  return (
    <section className="about grid">
      <div className="box grid">
        <Marquee banner={[{ text: title }]} />
        <p className="about__subheading copy">{subheadingCopy}</p>
        <div className="about__visual">
          <Avatar data={team} />
        </div>
        <div className="about__link">
          <Link to="/about">
            <BtnSecondary ctaText={'das ganze Team'} />
          </Link>
        </div>
        <div className="about__benefits grid">
          <h3 className="heading--secondary">Deine Vorteile</h3>
          <ul role="list" className="grid marker">
            {benefits.map((item, index) => (
              <li key={index}>{item.benefit}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
