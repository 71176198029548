import * as React from 'react'

// COMPONENTS
import IconIG from '../../svgs/trust_ig.inline.svg'
import IconFB from '../../svgs/trust_fb.inline.svg'
import IconTikTok from '../../svgs/trust_tiktok.inline.svg'
import IconShopify from '../../svgs/trust_shopify.inline.svg'

export const TrustBadges = () => {
  return (
    <div className="trust">
      <div className="wrapper grid">
        <IconShopify className="icon__shopify" />

        <IconFB />

        <IconTikTok />

        <IconIG />
      </div>
    </div>
  )
}
