import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

// COMPONENTS
import BgBlob from '../../svgs/bg_blob.inline.svg'
import { TrustBadges } from '../TrustBadges/TrustBadges'
import { Video } from '../Media/Video'

export const Hero = ({ title, subtitle, ctaHero, ctaHeroLink, video }) => {
  const imgSrc = '../../assets/images/shell-phone.png'
  return (
    <section className="hero">
      <div className="wrapper grid">
        <div className="hero__copy grid">
          <h1 className="hero__headline heading--primary">{title}</h1>
          <p className="hero__subheading">{subtitle}</p>
          <Link
            to={ctaHeroLink}
            className="hero__cta btn btn--primary"
            title="Jetzt starten"
          >
            {ctaHero}
          </Link>
        </div>
        <div className="hero__visual">
          <div className="bg--blobs">
            <BgBlob />
          </div>
          <div className="hero__video-container">
            <div className="hero__frame">
              <Video
                className={'hero__video-frame'}
                id={'hero__video-frame'}
                src={video}
                autoplay={true}
              />
              <StaticImage
                alt=""
                className="wrapper-phone-mockup-frame"
                imgClassName="phone-mockup-frame"
                src={imgSrc}
                loading="eager"
              />
            </div>

            <div className="hero__video-bg"></div>
          </div>
        </div>

        <TrustBadges />
      </div>
    </section>
  )
}
