import * as React from 'react'
import { Link } from 'gatsby'

// COMPONENTS
import LogoSVG from '../../svgs/logo.inline.svg'

export const Logo = () => {
  return (
    <Link
      to="/"
      aria-label="Zurück zur Startseite"
      title="Contective - Startseite"
      className="logo"
    >
      <LogoSVG />
    </Link>
  )
}
