import { Builder, withChildren } from '@builder.io/react'
import { Pricing } from './Pricing'

const PricingWithChildren = withChildren(Pricing)

Builder.registerComponent(PricingWithChildren, {
  name: 'PricingWithChildren',
  inputs: [
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'explanations',
      type: 'list',
      defaultValue: [{ explanation: '' }],
      subFields: [
        { name: 'heading', type: 'string' },
        { name: 'explanation', type: 'longText' }
      ]
    }
  ]
})
