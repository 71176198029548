import { Builder, withChildren } from '@builder.io/react'
import { About } from './About'
import { AboutPage } from './AboutPage'

const AboutPageWithChildren = withChildren(AboutPage)

Builder.registerComponent(About, {
  name: 'About',
  inputs: [
    { name: 'title', type: 'string' },
    { name: 'subheadingCopy', type: 'longText' },
    {
      name: 'team',
      type: 'list',
      defaultValue: [{ name: '' }],
      friendlyName: 'Team Members',
      max: 6,
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: ''
        },
        { name: 'job', type: 'string', defaultValue: '' },
        {
          name: 'img',
          type: 'file',
          allowedFileTypes: ['png'],
          defaultValue: '',
          helperText:
            'Nur png Dateien erlaubt. Bitte vor Upload einmal das Bild durch ein Background-Removal-Tool jagen (z.B. https://www.remove.bg/). Optimale Größe 500x500px.'
        },
        { name: 'offer', type: 'boolean' }
      ]
    },
    {
      name: 'benefits',
      type: 'list',
      defaultValue: [{ benefit: '' }],
      subFields: [
        {
          name: 'benefit',
          type: 'string',
          defaultValue: ''
        }
      ]
    }
  ]
})

Builder.registerComponent(AboutPageWithChildren, {
  name: 'About Page',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Test'
    },
    {
      name: 'content',
      type: 'list',
      defaultValue: [{ section: '' }],
      subFields: [
        {
          name: 'category',
          type: 'string'
        },
        {
          name: 'headline',
          type: 'string'
        },
        {
          name: 'copy',
          type: 'list',
          defaultValue: [{ text: '' }],
          subFields: [
            {
              name: 'text',
              type: 'longText'
            }
          ]
        }
      ]
    },
    {
      name: 'teamHeading',
      type: 'string'
    },
    {
      name: 'team',
      type: 'list',
      defaultValue: [{ name: '' }],
      subFields: [
        {
          name: 'name',
          type: 'string'
        },
        { name: 'profession', type: 'string' },

        {
          name: 'funFact',
          type: 'longText'
        },
        {
          name: 'img',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png']
        },
        {
          name: 'altText',
          type: 'longText'
        }
      ]
    }
  ]
})
