import * as React from 'react'
import { Image } from '@builder.io/react'

// COMPONENTS
import { Title } from '../HeadingSection/Title'
import { VerticalSlider } from '../VerticalSlider/VerticalSlider'

export const AboutPage = (props) => {
  const { title, content, teamHeading, team } = props

  return (
    <section className="team">
      <div className="team__visual">
        <div className="wrapper">
          <Title as="h1" pageHeading={true}>
            {title}
          </Title>
          <Title as={'h2'} textPos={'center'}>
            {teamHeading}
          </Title>
          <ul className="team__cards grid" role="list">
            {team.map((item, index) => (
              <li key={index}>
                <div className="team__card grid">
                  <Image
                    alt={item.altText}
                    className="team__card-img"
                    image={item.img}
                    lazy
                  />
                  <div className="team__card-content">
                    <h3 className="team__card-name">{item.name}</h3>
                    <p className="team__card-job">{item.profession}</p>
                    <p className="team__card-funfact">{item.funFact}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="team__values wrapper grid">
        <VerticalSlider content={content} />
      </div>

      {props.children}
    </section>
  )
}
