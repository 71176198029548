import * as React from 'react'

// COMPONENTS
import BgDotsSVG from '../../svgs/bg_dots.inline.svg'

export const BgDots = () => {
  return (
    <div className="bg__dots">
      <BgDotsSVG />
    </div>
  )
}
