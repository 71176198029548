import * as React from 'react'
import { Link } from 'gatsby'
import { Image } from '@builder.io/react'

export const Avatar = ({ data }) => {
  return (
    <ul className="grid">
      {data.map((avatar, index) =>
        avatar.offer ? (
          <li key={index}>
            <Link to="/jobs" className="avatar__offer">
              <article className="avatar grid">
                <div className="avatar__img"></div>
                <div className="avatar__details">
                  <p className="avatar__name">{avatar.name}</p>
                  <p className="avatar__job">{avatar.job}</p>
                </div>
              </article>
            </Link>
          </li>
        ) : (
          <li key={index}>
            <article className="avatar grid">
              <div>
                <Image image={avatar.img} className="avatar__img" lazy />
              </div>
              <div className="avatar__details">
                <p className="avatar__name">{avatar.name}</p>
                <p className="avatar__job">{avatar.job}</p>
              </div>
            </article>
          </li>
        )
      )}
    </ul>
  )
}
