import * as React from 'react'
import { Link } from 'gatsby'

// COMPONENTS
import IconMail from '../../svgs/contact_mail.inline.svg'

export const StickyCTA = ({ ctaText }) => {
  return (
    <div className="sticky__cta">
      <Link
        className="link__contact-mail flex-row"
        to="/contact"
        aria-label="Jetzt Kontakt aufnehmen"
      >
        {ctaText ? ctaText : <IconMail />}
      </Link>
    </div>
  )
}
